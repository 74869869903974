exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bizi-ekintzak-strapi-ekintza-slug-tsx": () => import("./../../../src/pages/bizi/ekintzak/{strapiEkintza.slug}.tsx" /* webpackChunkName: "component---src-pages-bizi-ekintzak-strapi-ekintza-slug-tsx" */),
  "component---src-pages-bizi-index-tsx": () => import("./../../../src/pages/bizi/index.tsx" /* webpackChunkName: "component---src-pages-bizi-index-tsx" */),
  "component---src-pages-bizi-zikloak-strapi-zikloa-slug-tsx": () => import("./../../../src/pages/bizi/zikloak/{strapiZikloa.slug}.tsx" /* webpackChunkName: "component---src-pages-bizi-zikloak-strapi-zikloa-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kafetegia-tsx": () => import("./../../../src/pages/kafetegia.tsx" /* webpackChunkName: "component---src-pages-kafetegia-tsx" */),
  "component---src-pages-lege-oharra-tsx": () => import("./../../../src/pages/lege-oharra.tsx" /* webpackChunkName: "component---src-pages-lege-oharra-tsx" */),
  "component---src-pages-pribatutasun-politika-tsx": () => import("./../../../src/pages/pribatutasun-politika.tsx" /* webpackChunkName: "component---src-pages-pribatutasun-politika-tsx" */)
}

